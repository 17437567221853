import { graphql, useStaticQuery } from "gatsby";
import React, { createContext, useContext, useMemo } from "react";

export interface AssetDetails {
  _id: string;
  title?: string | null;
  filename?: string | null;
  originalFilename?: string | null;
  altText?: string | null;
  url?: string | null;
}
interface AssetCacheContextType {
  getFileDetails: (fileId: string | undefined) => AssetDetails | undefined;
  getImageDetails: (imageId: string | undefined) => AssetDetails | undefined;
}

const AssetCacheContext = createContext<AssetCacheContextType | undefined>(undefined);

export const AssetCacheProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const data = useStaticQuery(graphql`
    query GetAllSanityAssets {
      allSanityFileAsset {
        nodes {
          _id
          title
          originalFilename
          altText
          url
        }
      }
      allSanityImageAsset {
        nodes {
          _id
          title
          originalFilename
          filename
          altText
          url
        }
      }
    }
  `);

  const fileMap = useMemo(() => {
    const map = new Map<string, AssetDetails>();
    data.allSanityFileAsset.nodes.forEach(file => {
      map.set(file._id, file);
    });
    return map;
  }, [data]);

  const imageMap = useMemo(() => {
    const map = new Map<string, AssetDetails>();
    data.allSanityImageAsset.nodes.forEach(image => {
      map.set(image._id, image);
    });
    return map;
  }, [data]);

  const getFileDetails = (id: string | undefined) =>
    typeof id === "string" ? fileMap.get(id) : undefined;
  const getImageDetails = (id: string | undefined) =>
    typeof id === "string" ? imageMap.get(id) : undefined;

  return (
    <AssetCacheContext.Provider value={{ getFileDetails, getImageDetails }}>
      {children}
    </AssetCacheContext.Provider>
  );
};

export const useAssetCache = () => {
  const context = useContext(AssetCacheContext);
  if (!context) {
    throw new Error("useAssetCache must be used within an AssetCacheProvider");
  }
  return context;
};

