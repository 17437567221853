/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { GatsbySanityPreviewContextProvider } from "@tedgustaf/gatsby-plugin-sanity-preview";
import { WrapPageElementBrowserArgs, WrapRootElementBrowserArgs } from "gatsby";
import React from "react";
import "slick-carousel/slick/slick.css";
import siteConfig from "./src/config/siteConfig";
import { AssetCacheProvider } from "./src/context/AssetCacheContext";
import { CoursesContextProvider } from "./src/context/CoursesContext";
import { DialogContextProvider } from "./src/context/DialogContext";
import { HydrationContextProvider } from "./src/context/HydrationContext";
import { LinkContextProvider } from "./src/context/LinkContext";
import { LocalizationContextProvider } from "./src/context/LocalizationContext";
import { PageContextProvider } from "./src/context/PageContext";
import { PageRatingContextProvider } from "./src/context/PageRatingContext";
import { GatsbyPageContext } from "./src/models/pages/GatsbyPageContext";
import { ensureSessionGUID, getGAClientId } from "./src/utilities/gaUserTracking";
import { sanityClientConfig } from "./src/utilities/sanityClient";

// Using require instead of "regular" import to support string interpolation.
// Note: dynamic import statements (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#Dynamic_Imports)
// are not supported by Gatsby.
require(`./src/styles/themes/${siteConfig.theme}/index.css`);

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => (
  <GatsbySanityPreviewContextProvider
    enabled={process.env.GATSBY_IS_PREVIEW === "true"}
    sanityClientConfig={sanityClientConfig}
  >
    <DialogContextProvider>{element}</DialogContextProvider>
  </GatsbySanityPreviewContextProvider>
);

export const wrapPageElement = ({
  element,
  props
}: WrapPageElementBrowserArgs<unknown, GatsbyPageContext>) => (
  <LocalizationContextProvider currentLanguage={props.pageContext?.i18nLang}>
    <PageContextProvider pageContext={props.pageContext}>
      <AssetCacheProvider>
        <PageRatingContextProvider
          pageId={props.pageContext._id ?? ""}
          rawPageRating={props.pageContext.rawPageRating}
        >
          <CoursesContextProvider>
            <HydrationContextProvider>
              <LinkContextProvider>{element}</LinkContextProvider>
            </HydrationContextProvider>
          </CoursesContextProvider>
        </PageRatingContextProvider>
      </AssetCacheProvider>
    </PageContextProvider>
  </LocalizationContextProvider>
);

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV === "production") {
    setTimeout(() => {
      const eventName = "user-identification";
      const clientId = getGAClientId();

      if (clientId) {
        /* eslint-disable camelcase */
        const data = {
          event: eventName,
          client_id: clientId,
          client_id_session: ensureSessionGUID(),
          path_name: location?.pathname,
          page_title: document?.title
        };

        /* eslint-enable camelcase */
        window["dataLayer"] = window["dataLayer"] || [];
        window["dataLayer"].push(data);
      }
    }, 50);

    // push route change event to google tag manager when not using the tag manager plugin
    if (siteConfig.tagManagerDisablePlugin) {
      // wrap inside a timeout to ensure the title has properly been changed
      setTimeout(() => {
        if (window?.["dataLayer"]) {
          const data = window["dataLayer"];
          const eventName = "gatsby-route-change";

          data.push({ event: eventName });
        }
      }, 50);
    }
  }
};
